import styled from '@emotion/styled'
import { BigWheelNextConditionContainer, CoinVector, CheckMark, XMark } from 'assets/images';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px;
  z-index: 2;
  display: ${props => props.visible ? 'flex' : 'none'};

  .reveal-side-bet-win {
    position: absolute;
    opacity: 0;
  }

`;

export const Condition = styled.div`
  background-image: url(${BigWheelNextConditionContainer});
  background-size: 100% 100%;
  width: 75px;
  height: 70px;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fcf092;
`;

export const Sub = styled.div`
  font-size: 10.5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Coin = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  max-height: 100px;
  width: 70px;
  height: 70px;
  text-shadow: 0px 1px 0 #fdf193;
  font-family: Roboto;
  font-size: 0.9em;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;


  etter-spacing: normal;
  text-align: center;
  color: #4c2b08;
  margin-top: 20px;

  &:after {
    ${props => props.visible ? `
    content: none;` : `
    content: ' ';`}
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`;

export const Inner = styled.div`
  z-index: 2;
  width: 70px;
  height: 70px;
  background-image: url(${CoinVector});
  background-size: 70px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 1;

  .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    clip: rect(0px, 60px, 60px, 30px);

    .progress {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      clip: rect(0px, 30px, 60px, 0px);
      background: rgb(128, 253, 49);
    }
  }

  .left {
    .progress{
      z-index:1;
      animation: left 4s linear both;
    }
  }

  @keyframes left {
    100%{
      transform: rotate(180deg);
    }
  }

  .right {
    transform: rotate(180deg);
    z-index:3;

    .progress {
      animation: right 4s linear both;
      animation-delay: 4s;
    }
  }

  @keyframes right{
    100%{
      transform: rotate(180deg);
    }
  }
`;

export const MarkContainer = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${props => props.win ? CheckMark : XMark});
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;